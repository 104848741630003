<template>
  <div>
    <nav-bar></nav-bar>
    <div class="main ovh">
      <div class="bigword">享受自我赋能</div>
      <div class="appstore public">
        <div class="app-title">iPhone</div>
        <img src="./status/iphone.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        <img class="btn" src="./status/appstore.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访"  @click="appleDnload">
      </div>
      <div class="android public" @click="android">
        <div class="and-title">Android</div>
        <img src="./status/android.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        <img class="btn" src="./status/android2.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访"  @click="android">
      </div>
      <div class="wechat public">
        <div class="wechat-title">WeChat</div>
        <img src="./status/wechat.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        <el-popover
          placement="top-start"
          trigger="hover"
          visible-arrow="true">
          <img class="imgsize" src="./status/qcode.jpg" alt="">
          <img class="btn" slot="reference" src="./status/mini.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        </el-popover>
      </div>
      <!-- 移动端轮播图 -->
      <van-swipe @change="changeIndex" :height="573" ref="carousel" class="swipe ovh">
           <van-swipe-item class="fl">
              <div class="appstore grid">
                <div class="app-title">iPhone</div>
                <img src="./status/iphone.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
                <img class="btn" src="./status/appstore.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访" @click="appleDnload">
              </div>
           </van-swipe-item>
           <van-swipe-item class="fl">
             <div class="android grid">
               <div class="and-title">Android</div>
               <img src="./status/android.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
               <img class="btn" src="./status/android2.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访" @click="android">
             </div>
           </van-swipe-item>
           <van-swipe-item class="fl">
             <div class="wechat grid">
               <div class="wechat-title">WeChat</div>
               <img src="./status/wechat.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
               <el-popover
                  placement="top-start"
                  trigger="click">
                  <img class="imgsize" src="./status/qcode.jpg" alt="">
                  <img class="btn" slot="reference" src="./status/mini.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
                </el-popover>
             </div>
           </van-swipe-item>
      </van-swipe>
      <div class="cirbtns ovh" slot="indecator">
        <div class="cirbtn fl" :class="{'active':current === 0}" @click="changeItem(0)"></div>
        <div class="cirbtn fl" :class="{'active':current === 1}" @click="changeItem(1)"></div>
        <div class="cirbtn fl" :class="{'active':current === 2}" @click="changeItem(2)" style="margin-right:0"></div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import NavBar from '@/components/nav.vue'
import Foot from '@/components/foot.vue'

export default {
  components: {
    NavBar,
    Foot
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    changeIndex (index) {
      this.current = index
    },
    changeItem (index) {
      if (index === 0) {
        this.$refs.carousel.swipeTo(0)
      } else if (index === 1) {
        this.$refs.carousel.swipeTo(1)
      } else if (index === 2) {
        this.$refs.carousel.swipeTo(2)
      }
    },
    android () {
      this.$http.get('https://api.fillgreat.com/release/app_update/latest_package_resource')
        .then((res) => {
          if (res.data.code === 0) {
            window.open(res.data.data.update_resource_url)
          } else {
            alert(res.message)
          }
        })
    },
    appleDnload () {
      window.location.href = 'https://apps.apple.com/cn/app/飞观/id1471936108'
    }
  },
  mounted () {
    // 根据操作系统设置显示下载按钮
    var phone = window.navigator.userAgent.toLowerCase()
    if (phone.indexOf('android') !== -1) {
      this.current = 1
      this.$refs.carousel.swipeTo(1)
    } else {
      this.current = 0
      this.$refs.carousel.swipeTo(0)
    }
  }
}
</script>

<style scoped>
   .main {
     width: 1160px;
     margin: 0 auto;
   }
   .bigword {
     width:276px;
     height:65px;
     font-size:46px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:800;
     color:rgba(54,54,54,1);
     line-height:65px;
     margin: 31px auto 39px;
   }
   .download {
     width: 100%;
   }
   .appstore,.android,.wechat {
     width: 281px;
     float: left;
     margin-right: 158px;
   }
   .wechat {
     margin-right: 0;
   }
   .appstore img,.android img {
     width: 100%;
   }
   .wechat img {
     width: 277px;
   }
   .app-title,.and-title,.wechat-title {
     width:100%;
     height:65px;
     font-size:46px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:800;
     color:rgba(54,54,54,1);
     line-height:65px;
     margin: 0 auto;
   }
   .imgsize {
     width: 200px;
     height: 200px;
     border-radius: 11px;
   }
   .btn {
     width: 221px !important;
     border-radius: 12px;
     cursor: pointer;
     border: 2px solid black;
     box-sizing: border-box;
     margin-bottom: 132px;
   }
   .wechat .btn {
     border: 2px solid #19ad19;
   }
   .swipe {
     width: 100%;
     display: none;
   }
   .swipe >>> .grid {
     margin: 0 auto;
   }
   .cirbtns {
     width: 99px;
     height: 21px;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     display: none;
   }
   .van-swipe__track {
     overflow: hidden;
   }
   .cirbtn {
     width:21px;
     height:21px;
     background:rgb(216, 216, 219);
     border-radius: 50%;
     margin-right: 18px;
     cursor: pointer;
   }
   .active {
     background:rgba(248,40,40,1);
   }
   @media screen and (max-width: 1200px) {
     .main {
       width: 878px;
     }
     .appstore,.android,.wechat {
       width: 221px;
       margin-right: 95px;
     }
     .wechat {
       margin-right: 0;
     }
     .btn {
       width: 180px !important;
     }
     .wechat img {
       width: 216px ;
     }
   }
   @media screen and (max-width: 992px) {
     .main {
       width: 100%;
       height: 858px;
     }
     .public {
       display: none;
     }
     .swipe {
       display: block;
     }
     .grid {
       float: none;
     }
     .btn {
       margin-bottom: 29px;
     }
     .cirbtns {
       display: block;
     }
     .bigword {
       width: 90%;
       margin-top: 91px;
     }
   }
   @media screen and (max-width: 576px) {
    .bigword {
      font-size: 36px;
    }
    .app-title,.and-title,.wechat-title {
      font-size: 32px;
    }
   }
</style>
